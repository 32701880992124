.navbar-nav {
	.nav-link {
		padding: 0.75rem 1rem 0.5rem;
		font-size: 1.4rem;
		font-weight: $font-weight-bold;
		border-top: 1px solid $gray-300;

		@include media-breakpoint-up(lg) {
			line-height: 2.5;
			border-top: 0 none;
		}
		@include media-breakpoint-up(xl) {
			font-size: 1.5rem;
		}

		.fa-stack {
			font-size: 1.5rem;
		}
	}
}

.btn {
	background-color: transparent !important;
	text-transform: uppercase;
}
.btn-lg {
	padding: $btn-padding-y-lg * 1.5 $btn-padding-x-lg $btn-padding-y-lg;
}
.btn-light {
	color: $gray-100;

	&:hover {
		color: $primary;
	}
}
.btn-primary {
	color: $primary;

	&:hover {
		color: $secondary;
	}
}

.form-control-lg {
	padding: $input-padding-y-lg * 1.33 $input-padding-x-lg $input-padding-y-lg *
		0.66;
}
