@import url('https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;500;600&display=swap');
@import './_variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import './_bootstrap';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands';

h1,
.h1,
h2,
.h2 {
	font-weight: $font-weight-light;
	text-transform: uppercase;
}
@include media-breakpoint-up(md) {
	h1,
	.h1 {
		@include font-size($h1-font-size-md);
	}
	h2,
	.h2 {
		@include font-size($h2-font-size-md);
	}
	h3,
	.h3 {
		@include font-size($h3-font-size-md);
	}
	h4,
	.h4 {
		@include font-size($h4-font-size-md);
	}
	h5,
	.h5 {
		@include font-size($h5-font-size-md);
	}
}

#header {
	height: $fixed-nav-size;
	box-shadow: 0 0 $shadow-size rgba(0, 0, 0, 0.5);

	@include media-breakpoint-only(md) {
		height: auto;
	}
	@include media-breakpoint-up(lg) {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: $zindex-fixed;
	}

	#navbar-row {
		padding-bottom: $grid-gutter-width * 2.5;

		@include media-breakpoint-only(md) {
			padding-bottom: 0;

			#logo-md-container {
				padding-top: $grid-gutter-width / 2;
				padding-bottom: $grid-gutter-width / 2;
			}
		}
		@include media-breakpoint-up(lg) {
			padding-bottom: $grid-gutter-width * 4;
		}

		#navbar-container {
			padding: 0 $grid-gutter-width;

			@include media-breakpoint-up(md) {
				padding-right: 0;
			}

			#navbar {
				padding: 0 0 0.5rem;
				box-shadow: 0 $shadow-size $shadow-size rgba(0, 0, 0, 0.5);

				@include media-breakpoint-up(lg) {
					margin-left: -1rem;
					margin-right: -1rem;
					padding: 0.5rem 0;
					box-shadow: none;
				}
			}
		}
	}
}

#main {
	position: relative;

	&.default {
		background-color: rgb(212, 237, 238);

		@include media-breakpoint-up(md) {
			background-image: url('../img/main-bg-2.png');
			background-repeat: no-repeat;
			background-position: bottom 40% right 0;
			background-size: 152px 241px;
		}

		#main-content {
			padding: $grid-gutter-width * 4 $grid-gutter-width * 1.5 0;
			box-shadow: none;

			@include media-breakpoint-up(lg) {
				padding: $grid-gutter-width * 4 $grid-gutter-width * 2.5 0;
			}
		}
	}

	#main-bg-1,
	#main-bg-2 {
		position: absolute;
		left: 0;
		right: 0;
	}
	#main-bg-1 {
		top: $fixed-nav-size;
		bottom: 50%;
		background-color: $gray-500;

		@include media-breakpoint-only(md) {
			top: 0;
		}
		@include media-breakpoint-up(md) {
			background-image: url('../img/main-bg-1.jpg');
			background-size: cover;
		}
	}
	#main-bg-2 {
		top: 50%;
		bottom: 0;
		background-color: rgb(212, 237, 238);

		@include media-breakpoint-up(md) {
			background-image: url('../img/main-bg-2.png');
			background-repeat: no-repeat;
			background-position: top 20px left 0;
			background-size: 152px 241px;
		}
	}

	#logo-row {
		padding-bottom: $grid-gutter-width * 2.5;

		@include media-breakpoint-up(md) {
			padding-bottom: $grid-gutter-width * 4;
		}

		&.blog {
			padding-bottom: 0;
		}

		#logo-container {
			padding: 0 $grid-gutter-width;

			@include media-breakpoint-up(md) {
				padding-left: 0;
			}
			@include media-breakpoint-up(lg) {
				&.no-intro {
					min-height: 422px;
				}
				&.home-video {
					padding-right: 0;
				}
			}

			#logo {
				position: relative;
				z-index: $zindex-fixed;
				display: block;
				width: 100%;
				padding: $grid-gutter-width * 1.5 $grid-gutter-width;
				background-color: $white;
				box-shadow: 0 0 $shadow-size rgba(0, 0, 0, 0.5);

				@include media-breakpoint-up(md) {
					display: flex;
				}

				&.logo-fixed {
					position: fixed;
				}
				&.logo-sml {
					padding: 0.5rem $grid-gutter-width;
				}
				@include media-breakpoint-up(lg) {
					&.small {
						margin-bottom: $grid-gutter-width * 8;
					}
				}

				.logo-img {
					display: inline-block;
					margin: 0 auto;
					align-self: flex-end;
				}
				#logo-img-sml {
					display: none;
				}
			}
		}
		#introduction-container {
			padding: 0 $grid-gutter-width;

			@include media-breakpoint-only(md) {
				padding-left: 0;
			}
			@include media-breakpoint-up(md) {
				padding-right: 0;
			}
			@include media-breakpoint-up(lg) {
				&.home-video {
					padding-left: 0;
				}
			}

			#home-video {
				display: block;
				@include media-breakpoint-up(lg) {
					height: 500px;
				}
			}
			#navbar-xs {
				z-index: $zindex-fixed;
				padding: 0 0 0.5rem;
				box-shadow: 0 $shadow-size $shadow-size rgba(0, 0, 0, 0.5);
			}
			#introduction {
				margin-top: $grid-gutter-width * 2.5;
				padding: $grid-gutter-width;
				color: $white;
				background-color: $secondary;

				@include media-breakpoint-up(lg) {
					margin-top: $grid-gutter-width * 1.6 + $fixed-nav-size;
				}

				h1 {
					color: rgb(251, 176, 64);
				}
				p:last-child {
					margin-bottom: 0;
				}
			}
		}
		#home-introduction {
			position: relative;
			padding: $grid-gutter-width * 1.5;
			color: $white;
			background-color: $secondary;

			h1 {
				color: rgb(251, 176, 64);
			}
			p:last-child {
				margin-bottom: 0;
			}
		}
	}

	#main-content {
		position: relative;
		padding: $grid-gutter-width;
		background-color: $white;
		box-shadow: $shadow-size $shadow-size $shadow-size rgba(0, 0, 0, 0.5);

		&.blog {
			padding: $grid-gutter-width 0;
			background-color: transparent;

			aside {
				padding: $grid-gutter-width;
				background-color: $white;

				h3 {
					color: $secondary;
					text-transform: uppercase;
				}
			}
			article {
				margin-bottom: $grid-gutter-width;
				background-color: $white;

				.blog-article {
					padding: $grid-gutter-width;

					@include media-breakpoint-up(lg) {
						padding: $grid-gutter-width * 1.5 $grid-gutter-width
							$grid-gutter-width * 1.5 $grid-gutter-width * 1.5;

						&.odd {
							padding: $grid-gutter-width * 1.5 $grid-gutter-width *
								1.5 $grid-gutter-width * 1.5 $grid-gutter-width;
						}
					}
				}
			}

			.blog-article {
				padding: $grid-gutter-width;

				img {
					max-width: 100%;
					height: auto;
				}

				@include media-breakpoint-up(lg) {
					padding: $grid-gutter-width * 2.5;
				}
			}
		}

		h1,
		.h1 {
			color: $primary;
		}
		h2,
		.h2 {
			color: $secondary;
			text-transform: none;
		}

		.hero-name {
			position: relative;
			margin: $grid-gutter-width * -1 $grid-gutter-width * 1.5
				$grid-gutter-width;
			padding: $grid-gutter-width / 3 $grid-gutter-width / 3
				$grid-gutter-width / 4;
			color: $white;
			font-size: 1.5rem;
			line-height: 1.2;
			text-align: center;

			&.hero-1 {
				background-color: rgb(241, 90, 41);
			}
			&.hero-2 {
				background-color: rgb(101, 200, 206);
			}
			&.hero-3 {
				background-color: rgb(25, 120, 60);
			}
			&.hero-4 {
				background-color: rgb(251, 197, 181);
			}
			&.hero-5 {
				background-color: rgb(251, 176, 64);
			}
			&.hero-6 {
				background-color: rgb(198, 22, 141);
			}
			&.hero-7 {
				background-color: rgb(125, 186, 85);
			}
			&.hero-8 {
				background-color: rgb(35, 64, 149);
			}
			&.hero-9 {
				background-color: rgb(255, 208, 73);
			}
		}
	}
	#archives {
		position: relative;
		padding: $grid-gutter-width $grid-gutter-width * 1.5;
		color: $secondary;
		background-color: rgb(255, 233, 168);
		box-shadow: $shadow-size $shadow-size $shadow-size rgba(0, 0, 0, 0.5);

		@include media-breakpoint-up(md) {
			padding: $grid-gutter-width $grid-gutter-width * 2;
		}

		#archives-content {
			padding-top: $grid-gutter-width;
			padding-bottom: $grid-gutter-width;
		}
	}
}

#footer {
	position: relative;
	padding-bottom: $grid-gutter-width * 5;
	color: $white;
	background-color: $secondary;
	background-image: url('../img/footer-bg.png');
	background-repeat: no-repeat;
	background-position: bottom 0 right -35px;
	background-size: 298px 700px;

	#supporters {
		margin-bottom: $grid-gutter-width * 3;
		padding: $grid-gutter-width * 2;
		color: $secondary;
		background-color: $white;
		box-shadow: $shadow-size $shadow-size $shadow-size rgba(0, 0, 0, 0.5);

		&.default {
			box-shadow: none;
		}

		.logo {
			margin-top: $grid-gutter-width * 2;
			padding: $grid-gutter-width 0 round($grid-gutter-width * 0.66);
			font-size: 2rem;
			font-weight: $font-weight-normal;
			text-align: center;
			text-transform: uppercase;
			background-color: $gray-200;
		}
		img.logo {
			padding: 0;
		}
	}

	#contact-us-follow-us {
		section {
			margin-top: $grid-gutter-width * 2;
			padding: 0 $grid-gutter-width * 1.5;

			h2 {
				margin-bottom: $grid-gutter-width * 2;
			}
			.fa-stack {
				font-size: 1.75rem;
			}
		}
	}
}
