// Bootstrap
$primary: rgb(242, 89, 74);
$secondary: rgb(0, 137, 175);

$font-family-base: 'Baloo Tamma 2', cursive;
$font-size-base: 1.25rem;

$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;

$font-weight-base: $font-weight-light;
$line-height-base: 1.25;

$h1-font-size: $font-size-base * 1.8;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.4;
$h5-font-size: $font-size-base * 1.3;

$h1-font-size-md: $font-size-base * 2;
$h2-font-size-md: $font-size-base * 1.8;
$h3-font-size-md: $font-size-base * 1.6;
$h4-font-size-md: $font-size-base * 1.5;
$h5-font-size-md: $font-size-base * 1.4;

$headings-font-weight: 400;
$headings-line-height: 1;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$navbar-light-color: $secondary;
$navbar-light-hover-color: darken($primary, 15%);
$navbar-light-active-color: $primary;

$btn-border-width: 3px;
$btn-padding-x-lg: 5rem;
$btn-font-size-lg: 1.2rem;

// Custom
$fixed-nav-size: 96px;

$shadow-size: 0.5rem;

// FontAwesome
$fa-font-path: '../../node_modules/@fortawesome/fontawesome-free/webfonts';
